<template>
  <EmptyState
    v-if="!loading && !error && usage?.data?.length == 0"
    message="Sem consumos"
    buttonText=""
    :showButton="false"
  ></EmptyState>
  <div
    class="vl-parent"
    ref="formContainer"
    style="position: relative; min-height: 150px"
  >
    <div v-if="usage.data && usage.data.length">
      <div class="p-field mb-2" v-if="!loading">
        <Dropdown
          v-if="!loading && !error"
          :options="filterPositions"
          v-model="selectedFilter"
          optionLabel="name"
          @change="handleFilterSelection"
        />
      </div>

      <ErrorState
        v-if="!loading && error"
        @onErrorStateButtonClick="onErrorStateButtonClick()"
        message="Algo não correu bem"
        buttonText="Voltar a tentar"
      ></ErrorState>

      <Card class="card" v-if="!loading && !error">
        <template #content>
          <div>
            <!-- <h5>Horizontal</h5> -->
            <Chart
              type="doughnut"
              :data="stackedData"
              :options="stackedOptions"
              style="min-height: 300px"
              v-if="!loading"
            />
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { getConsumption } from "@/services/usage.service";
import EmptyState from "@/components/utils/EmptyState.vue";
import ErrorState from "@/components/utils/ErrorState.vue";

export default {
  name: "cataloglist",
  props: {
    showLoadingFullPage: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      loading: true,
      usage: {},
      error: true,
      selectedFilter: "",
      graphLabels: [],
      filterPositions: [],
      stackedOptions: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: "y",
        plugins: {
          title: {
            display: true,
            // align: "start",
            text: () => "Consumo de SMS por país",
          },
          tooltip: {
            enabled: false,
            position: "nearest",
            usePointStyle: true,
            external: function (context) {
              const { chart, tooltip } = context;

              //create tooltip
              let tooltipEl = chart.canvas.parentNode.querySelector("div");
              if (!tooltipEl) {
                tooltipEl = document.createElement("div");
                tooltipEl.style.background = "#2a2a33";
                tooltipEl.style.borderRadius = "3px";
                tooltipEl.style.color = "white";
                tooltipEl.style.opacity = 1;
                tooltipEl.style.pointerEvents = "none";
                tooltipEl.style.position = "absolute";
                tooltipEl.style.transform = "translate(+50%, -50%)";
                tooltipEl.style.transition = "all .1s ease";
                const table = document.createElement("table");
                table.style.margin = "0px";
                tooltipEl.appendChild(table);
                chart.canvas.parentNode.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              if (tooltip.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              //set Text
              if (tooltip.body) {
                // const titleLines = tooltip.title || [];
                const bodyLines = tooltip.body.map((b) => b.lines);

                // HEADER
                const tableHead = document.createElement("thead");
                const tr = document.createElement("tr");
                tr.style.borderWidth = 0;

                const th = document.createElement("th");
                th.style.borderWidth = 0;
                const text = document.createTextNode("Consumos");
                th.appendChild(text);
                th.style.marginBottom = "20px";
                th.style.fontSize = "24px";

                tr.appendChild(th);
                tableHead.appendChild(tr);

                //BODY
                const tableBody = document.createElement("tbody");

                bodyLines.forEach((body, i) => {
                  const colors = tooltip.labelColors[i];

                  if (i == 0 || i == 1 || i == 3) {
                    if (i > 0) {
                      tableBody.insertAdjacentHTML(
                        "beforeend",
                        '<tr><td><span><hr style="border:1px solid #404149"></span></td><tr>'
                      );
                    }

                    const span1 = document.createElement("span");
                    const tr = document.createElement("tr");
                    tr.style.fontSize = "14px";
                    tr.style.color = "#BBBCC4";
                    tr.style.backgroundColor = "inherit";
                    tr.style.borderWidth = 0;
                    const td = document.createElement("td");
                    td.appendChild(span1);
                    tr.appendChild(td);
                    tableBody.appendChild(tr);
                  }

                  const span = document.createElement("span");
                  span.style.background = colors.backgroundColor;
                  span.style.borderColor = colors.borderColor;
                  span.style.borderWidth = "2px";
                  span.style.marginRight = "10px";
                  span.style.height = "10px";
                  span.style.width = "10px";
                  span.style.display = "inline-block";
                  span.style.fontSize = "14px";

                  const tr = document.createElement("tr");
                  tr.style.backgroundColor = "inherit";
                  tr.style.borderWidth = 0;

                  const td = document.createElement("td");
                  td.style.borderWidth = 0;

                  var label = tooltip.dataPoints[i].raw + " consumidos";
                  var text = document.createTextNode(label);
                  td.appendChild(span);
                  td.appendChild(text);
                  tr.appendChild(td);
                  tableBody.appendChild(tr);
                });

                const tableRoot = tooltipEl.querySelector("table");
                // Remove old children
                while (tableRoot.firstChild) {
                  tableRoot.firstChild.remove();
                }
                // Add new children
                tableRoot.appendChild(tableHead);
                tableRoot.appendChild(tableBody);
              }

              const { offsetTop: positionY } = chart.canvas;

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.left = tooltip._eventPosition.x - 200 + "px";
              tooltipEl.style.top = positionY + tooltip.caretY + "px";
              tooltipEl.style.font = tooltip.options.bodyFont.string;
              tooltipEl.style.padding =
                tooltip.options.padding +
                "px " +
                tooltip.options.padding +
                "px";
            },
          },
          legend: {
            display: true,
            position: "bottom",
          },
        },
      },
      stackedData: {
        meta: [],
        labels: [],
        datasets: [],
      },
    };
  },
  components: {
    ErrorState,
    EmptyState,
  },
  methods: {
    onErrorStateButtonClick() {
      this.getData();
    },
    handleFilterSelection: function (event) {
      this.handleGraph(event.value.value);
    },
    handleGraph: function (idx) {
      this.stackedData.labels = [];
      this.stackedData.datasets = [
        {
          label: "D1",
          data: [],
          backgroundColor: [
            "rgb(54, 162, 235)",
            "rgb(75, 192, 192)",
            "rgb(255, 99, 132)",
            "rgb(255, 159, 64)",
            "rgb(255, 205, 86)",
            "rgb(153, 102, 255)",
            "rgb(201, 203, 207)",
          ],
        },
      ];

      this.usage.data[idx].packages.forEach((el) => {
        // //stack 0 for previous credit
        this.stackedData.labels.push(
          el.destinationCountryCode + " - " + el.quantity
        );
        this.stackedData.datasets[0].data.push(el.quantity);
      });
    },
    async getData() {
      this.error = false;
      this.loading = true;
      this.loader = this.$loading.show({
        container: this.showLoadingFullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });

      const [res, err] = await getConsumption();

      if (err) {
        this.error = true;
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: "Aconteceu um erro, por favor tente mais tarde!",
          life: 3000,
        });

        this.loader.hide();
      } else {
        this.usage = res;

        if (this.usage.data.length) {
          this.filterPositions = this.usage.data.map((el, idx) => {
            var rawDate = new Date(el.startDate);

            return {
              name:
                rawDate.getMonthName("pt") +
                " ( " +
                el.startDate +
                " a " +
                el.endDate +
                " )",
              value: idx,
            };
          });

          this.selectedFilter = this.filterPositions[0];

          //DEFAULT TO ZERO
          this.handleGraph(0);
        }
      }

      this.loader.hide();
      this.loading = false;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.p-dropdown {
  border: 0 none;
  background: none;
}

div.p-col {
  display: inline-flex;
  line-height: inherit;
  text-align: center;
  vertical-align: middle;
  line-height: 13px;
}

div.box {
  width: 13px;
  height: 13px;
  margin-right: 10px;
}
</style>
