<template>
  <Page
    title="Consumos SMS"
    :tabOptions="tabOptions"
    @onTabClick="changeSelectedTab"
  >
    <template v-slot:header> </template>
    <template v-slot:content>
      <div class="clients-content">
        <TabView :activeIndex="activeIndex">
          <!-- General -->
          <TabPanel header="Geral">
            <div class="grid">
              <div class="col-12 xl:col-6">
                <SmsStatistics v-if="activeIndex === 0"></SmsStatistics>
              </div>
              <div class="col-12 xl:col-6">
                <SmsConsumptions v-if="activeIndex === 0"></SmsConsumptions>
              </div>
            </div>
          </TabPanel>

          <!-- SMS Statistics -->
          <TabPanel header="Relatórios">
            <SmsBillingReport v-if="activeIndex === 1"></SmsBillingReport>
          </TabPanel>
        </TabView>
      </div>
    </template>
  </Page>
</template>

<script>
import Page from "@/components/Page.vue";
import SmsStatistics from "@/components/SmsStatistics.vue";
import SmsBillingReport from "@/components/SmsBillingReport.vue";
import SmsConsumptions from "@/components/SmsConsumptions.vue";

// import EmptyState from "@/components/utils/EmptyState.vue";
import AuthApp from "../main";

export default {
  data() {
    return {
      loading: false,
      activeIndex: 0,
      auth: AuthApp,
      tabOptions: [],
    };
  },
  beforeMount() {
    this.tabOptions = [
      { value: "Geral", selected: true, visible: true },
      {
        value: "Relatórios",
        selected: false,
        visible:
          this.auth.role == "super_admin" || this.auth.role == "admin"
            ? true
            : false,
      },
    ];
  },
  components: {
    Page,
    SmsStatistics,
    SmsBillingReport,
    SmsConsumptions,
  },
  methods: {
    changeSelectedTab(e) {
      this.tabOptions.forEach((element) => {
        element.selected = false;
      });
      this.tabOptions[e].selected = true;
      this.activeIndex = e;
    },
  },
};
</script>
